import styled from "styled-components";
export const ImageContainer = styled.div`
    width:100%;
    height:100%;
    display:flex;
    background-color:rgba(0,0,0,0.7);
    align-items:center;
    justify-content:center;
    z-index:1;
`;

export const StyledImage = styled.img`
    width:100%;
    height:100%;
    object-fit:cover;
    object-position:down;
    z-index:-1;
`;

