import styled from "styled-components";
import { device } from "../../../constants/device-sizes";
import { keyframes } from "styled-components";

export const ContactSectionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  @media ${device.laptop} {
    font-size: 40px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

const SectionContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  animation-timing-function: ease-out;
  animation-duration: 1s;
  animation-delay: ${(props) => props.delay};
  animation-fill-mode: both;
  animation-play-state: ${(props) => (props.isActive ? "running" : "paused")};
`;

const AnimationLeft = keyframes`
    0% {
      transform:translateX(-200%);
    }
    100% {
        transform:translateX(0px);
    }
`;

const AnimationRight = keyframes`
    0% {
      transform:translateX(200%);
    }
    100% {
        transform:translateX(0px);
    }
`;

export const SectionContainerLeft = styled(SectionContainer)`
  animation-name: ${AnimationLeft};
`;

export const SectionContainerRight = styled(SectionContainer)`
  animation-name: ${AnimationRight};
`;
