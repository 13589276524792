import styled from "styled-components";
import { keyframes } from "styled-components";
import { device } from '../../../constants/device-sizes';

export const CategoryPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;


const ButtonAnimation = keyframes`
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
`;

export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  height: 50%;
  padding-top:15px;
  padding-bottom:15px;
  box-shadow:10px 10px rgba(0,0,0,0.7);
  width:80%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: 0px;
  border-radius: 15px;
  transition: 500ms;
  font-size: 24px;
  font-family: "Big Shoulders Text";

  animation-name:${ButtonAnimation};
  animation-duration:1s;
  animation-delay: ${props=> props.delay};
  animation-fill-mode:both;
  animation-play-state: ${props=>props.isActive ? 'running' : 'paused'};
  
  :hover {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow:15px 15px rgba(255, 255, 0, 0.5);
    color: yellow;
  }

  @media ${device.laptop} { 
    font-size:50px;
  }

`;

export const IconPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width:15%;
`;

export const TextPlaceholder = styled.div`
  width:85%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right:15px;
  align-items: center;
`;
