import {
    ContentContainer,
    TitleContainer,
    IntroContainer,
    RowContainer,
    RowTextContainer,
} from './offer-content.styles'


const OfferContent = (props) =>{
    const {title, intro, row1, row2} = props;
    return(
        <ContentContainer>
            <TitleContainer>{title}</TitleContainer>
            <IntroContainer>{intro}</IntroContainer>
            <RowContainer>
                <RowTextContainer>{row1}</RowTextContainer>
                <RowTextContainer>{row2}</RowTextContainer>
            </RowContainer>
        </ContentContainer>
    )
}

export default OfferContent;