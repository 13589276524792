import HomeSection from "../../components/home/home-section/home-section.component";
import Navbar from "../../components/navbar/navbar.component";

import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from "swiper";

import "./swiper.css";

import AboutSection from "../../components/about/about-section.component";
import ContactSection from "../../components/contact/contact-section/contact-section.component";
import OfferSection from "../../components/offer/offer-section/offer-section.component";
import EvidenceSection from "../../components/evidence/evidence-section/evidence-section.component";
import { useEffect, useState } from "react";
import { useNavigate, useLocation, BrowserRouter } from "react-router-dom";

const RouterWrapper = () => {
  return (
    <BrowserRouter>
      <HomePage />
    </BrowserRouter>
  );
};

const slideSuffixes = ["/", "/about", "/offer", "/evidence", "/contact"];

const HomePage = () => {
  const [swiper, setSwiper] = useState(null);
  const [slideIndex, setSlideIndex] = useState(0);

  const history = useNavigate();
  const location = useLocation();

  let suffixIndex = slideSuffixes.indexOf(location.pathname);
  const startIndex = suffixIndex !== -1 ? suffixIndex : 0;

  const slideTo = (index) => {
    if (swiper !== null) {
      swiper.slideTo(index);
    }
  };

  useEffect(() => {
    if (window.screen.orientation && window.screen.orientation.lock) {
      window.screen.orientation.lock("portrait").catch((error) => {
      });
    }
  }, []);

  return (
    <>
      <Navbar currentSlide={slideIndex} slideTo={slideTo} />
      <Swiper
        direction={"vertical"}
        loop={true}
        mousewheel={true}
        keyboard={true}
        modules={[Mousewheel]}
        focusableElements={false}

        onSlideChange={(s) => {
          setSlideIndex(s.realIndex);
          history(slideSuffixes[s.realIndex]);
        }}
        onSwiper={(s) => {
          setSwiper(s);
        }}
        initialSlide={startIndex}
        simulateTouch={false}
        className="swiperMain swipermain"
      >
        <SwiperSlide className="homeslide">
          <HomeSection />
        </SwiperSlide>
        <SwiperSlide className="homeslide">
          <AboutSection isActive={slideIndex === 1} />
        </SwiperSlide>
        <SwiperSlide className="homeslide">
          <OfferSection isActive={slideIndex === 2} />
        </SwiperSlide>
        <SwiperSlide className="homeslide">
          <EvidenceSection isActive={slideIndex === 3} />
        </SwiperSlide>
        <SwiperSlide className="homeslide">
          <ContactSection isActive={slideIndex === 4} />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default RouterWrapper;
