import styled from "styled-components";
import { device } from "../../../constants/device-sizes";

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 8%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  z-index: 100;
`;

export const FooterText = styled.div`
  font-size: 1rem;
  @media ${device.laptop} {
    font-size: 1.5rem;
  }
`;
