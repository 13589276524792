import styled from "styled-components";
import CountUp from "react-countup";
import { keyframes } from "styled-components";
import { device } from "../../constants/device-sizes";

export const AboutSectionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: white;
  font-size: 11px;

  @media ${device.mobileM} {
    font-size: 16px;
  }
  @media ${device.laptop} {
    font-size: 24px;
  }
`;
export const AboutCardContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const AboutTextContainer = styled.div`
  height: 25%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: "Monda";
`;

const AnimationTextLeft = keyframes`
    0% {
      transform:translateX(-200%);
    }
    100% {
        transform:translateX(0px);
    }
`;

const AboutTextField = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3%;
  z-index: 1;
  text-align: center;

  animation-timing-function: ease-out;
  animation-duration: 1s;
  animation-delay: ${(props) => props.delay};
  animation-fill-mode: both;
  animation-play-state: ${(props) => (props.isActive ? "running" : "paused")};
  background-color: rgba(0, 0, 0, 0.3);
  @media ${device.laptop} {
    text-align: left;
  }
`;

const AnimationTextRight = keyframes`
    0% {
      transform:translateX(200%);
    }
    100% {
        transform:translateX(0px);
    }
`;

export const AboutTextFieldLeft = styled(AboutTextField)`
  animation-name: ${AnimationTextLeft};
`;

export const AboutTextFieldRight = styled(AboutTextField)`
  animation-name: ${AnimationTextRight};
`;

export const ImageContainer = styled.img`
  width: 100%;
  height: inherit;
  position: absolute;
  object-fit: cover;
  opacity: 0.4;
  @media ${device.laptop} {
    width: 25%;
    height: 100%;
    position: relative;
    padding: 3%;
    opacity: 1;
  }
`;

export const AboutNumbersContainer = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 3%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow-y: hidden;
  @media ${device.laptop} {
    flex-direction: row;
  }
`;

export const CounterContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const NumberContainer = styled(CountUp)`
  color: yellow;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  @media ${device.laptop} {
    font-size: 1.7em;
  }
`;
export const NumberInfoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  @media ${device.laptop} {
    font-size: 1.5em;
  }
`;
