import { useRef, useEffect } from "react";
import emailjs from "emailjs-com";

import {
  NameInput,
  PhoneMailInput,
  SelectInput,
  SelectOption,
  FormContainer,
  FormSection,
  FormRow,
  StyledTitle,
} from "../evidence-form.styles";
import Tooltip from "../../../shared/tooltip/tooltip.component";

const EvidenceFormFirst = (props) => {
  const {
    name,
    phone,
    mail,
    address,
    setEvidenceType,
    evidenceAddress,
    areaSize,
    height,
    usageYear,
  } = props;

  return (
    <FormContainer>
      <FormSection>
        <StyledTitle>Dane osobowe</StyledTitle>
        <Tooltip content="Imię i nazwisko">
          <NameInput
            placeholder="Imię i nazwisko"
            ref={name}
            name="from_name"
          />
        </Tooltip>
        <FormRow>
          <Tooltip content="Numer telefonu (bez kierunkowego)">
            <PhoneMailInput
              type="tel"
              placeholder="Numer telefonu"
              ref={phone}
              name="from_phone"
            />
          </Tooltip>
          <Tooltip content="Adres e-mail">
            <PhoneMailInput
              type="email"
              placeholder="Adres e-mail"
              ref={mail}
              name="from_mail"
            />
          </Tooltip>
        </FormRow>
        <Tooltip content="Adres klienta np. Polna 8A/3">
          <NameInput
            placeholder="Adres klienta np. Polna 8A/3"
            ref={address}
            name="from_address"
          />
        </Tooltip>
      </FormSection>
      <FormSection>
        <StyledTitle>Dane nieruchomości</StyledTitle>
        <FormRow>
          <Tooltip content="Rodzaj nieruchomości">
            <SelectInput
              placeholder="Rodzaj nieruchomości"
              name="from_evidenceType"
              onChange={(e)=>setEvidenceType(e.target.value)}
            >
              <SelectOption value="Mieszkanie">Mieszkanie</SelectOption>
              <SelectOption value="Dom">Dom</SelectOption>
              <SelectOption value="Lokal użytkowy">Lokal użytkowy</SelectOption>
            </SelectInput>
          </Tooltip>
          <Tooltip content="Adres nieruchomości np. Polna 8A/3">
            <NameInput
              placeholder="Adres nieruchomości np. Polna 8A/3"
              ref={evidenceAddress}
              name="from_evidenceAddress"
            />
          </Tooltip>
        </FormRow>
        <FormRow>
          <Tooltip content="Powierzchnia w m^2">
            <NameInput
              type="number"
              placeholder="Powierzchnia w m^2"
              ref={areaSize}
              name="from_areaSize"
            />
          </Tooltip>
          <Tooltip content="Wysokość w m">
            <NameInput
              type="number"
              placeholder="Wysokość w m"
              ref={height}
              name="from_height"
            />
          </Tooltip>
        </FormRow>
        <Tooltip content="Rok oddania budynku do użytku">
          <NameInput
            type="number"
            placeholder="Rok oddania budynku do użytku"
            ref={usageYear}
            name="from_usageYear"
          />
        </Tooltip>
      </FormSection>
    </FormContainer>
  );
};

export default EvidenceFormFirst;
