import { FooterContainer, FooterText } from "./author-footer.styles";

const AuthorFooter = () => {
  return (
    <FooterContainer>
      <FooterText>© 2023 - Autor: Kajetan Barwiński</FooterText>
    </FooterContainer>
  );
};

export default AuthorFooter;
