import{
    TooltipWrapper,
    TooltipTip
} from "./tooltip.styles.jsx";

import React, { useState } from "react";
const Tooltip = (props) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <TooltipWrapper
      // When to show the tooltip
      onFocus={showTip}
      onBlur={hideTip}
    >
      {/* Wrapping */}
      {props.children}
      {active && (
        
        <TooltipTip className={`${props.direction || "top"}`}>
          {/* Content */}
          {props.content}
        </TooltipTip>
      )}
    </TooltipWrapper>
  );
};

export default Tooltip;
