import styled from "styled-components";
import { device } from "../../../constants/device-sizes";
import { keyframes } from "styled-components";

export const EvidenceSectionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y:scroll;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  @media ${device.laptop} {
    font-size: 40px;
  }
`;

export const StyledTitle = styled.div`
  font-size: 1.1em;
  color: white;
`;

export const FormCard = styled.div`
  width: 100%;
  min-height: 80%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FormPlaceholder = styled.form`
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BaseInput = styled.input`
  color: white;
  background-color: #07070c;
  border: 0px;
  transition: 300ms;
  padding: 2%;
  font-family: "Monda";
  :focus {
    background-color: #1f2237;
  }
  font-size: 0.9rem;
  @media ${device.laptop} {
    font-size: 1.3rem;
  }
`;

export const SubmitButton = styled(BaseInput)`
  width: 60%;
  margin-top: 1rem;
  background-color: #07070c;
  :hover {
    background-color: #1f2237;
  }
`;

export const ButtonsContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const StyledButton = styled.button`
  color: white;
  background-color: #07070c;
  border: 1rem;
  transition: 300ms;
  padding: 2%;
  margin: 2%;
  font-family: "Monda";
  :focus {
    background-color: #1f2237;
  }
  font-size: 0.9rem;
  @media ${device.laptop} {
    font-size: 1.3rem;
  }
  width: 60%;
  margin-top: 1rem;
  background-color: #07070c;
  :hover {
    background-color: #1f2237;
  }
`;
