import ContactForm from "../contact-form/contact-form.component";
import ContactPersonals from "../contact-personals/contact-personals.component";
import AuthorFooter from "../author-footer/author-footer.component";
import {
  ContactSectionContainer,
  SectionContainerLeft,
  SectionContainerRight,
} from "./contact-section.styles";

const ContactSection = (props) => {
  return (
    <>
      <ContactSectionContainer>
        <SectionContainerLeft isActive={props.isActive}>
          <ContactForm />
        </SectionContainerLeft>
        <SectionContainerRight isActive={props.isActive}>
          <ContactPersonals
            name="Andrzej"
            email="kontakt.firma.abd@gmail.com"
            phone="TBA"
          />
          <ContactPersonals
            name="Piotr"
            email="kontakt.firma.abd@gmail.com"
            phone="TBA"
          />
        </SectionContainerRight>
      </ContactSectionContainer>
      <AuthorFooter />
    </>
  );
};

export default ContactSection;
