import { useState } from "react";
import { GiCctvCamera, GiToolbox, GiCancel } from "react-icons/gi";
import { FiAlertTriangle } from "react-icons/fi";
import { MdDialpad, MdNote } from "react-icons/md";

import CategoryButton from "../category-button/category-button.component";
import OfferContent from "../offer-content/offer-content.component";

import {
  OfferSectionContainer,
  OfferGridContainer,
  StyledModal,
  CloseButton,
  Wrapper,
} from "./offer-section.styles";
const OfferSection = (props) => {
  const modalContent = [
    () => {
      return (
        <OfferContent
          title="Świadectwa energetyczne"
          intro="Jako jedni z niewielu w Polsce, posiadamy uprawnienia do wystawiania świadectw energetycznych."
          row1="Posiadanie świadectwa jest konieczne m.in. przy zbyciu lub wynajmie nieruchomości."
          row2="Sporządzane przez nas dokumenty są w pełni oficjalne i sygnowane przez stronę rządową."
        />
      );
    },
    () => {
      return (
        <OfferContent
          title="Instalacje elektryczne"
          intro="Oferujemy częściowe lub kompleksowe wykonania w mieszkaniach, domach i firmach."
          row1="Do usług z tego zakresu zaliczamy pomiary elektryczne, 
          ocenę stanu instalacji, naprawy awaryjne oraz instalacje elektryczne od zera.
          Posiadamy niezbędne uprawnienia, zlecenia wykonywane są zgodnie z normami."
          row2="Usługi świadczymy na terenie Łodzi i okolic. Materiały z których korzystamy podczas pracy
          są kupowane w miarę możliwości od rodzimych, lokalnych przedsiębiorców."
        />
      );
    },
    () => {
      return (
        <OfferContent
          title="Systemy monitoringu"
          intro="Monitoring staje się coraz popularniejszą metodą zabezpieczenia. 
          Wykorzystanie współczesnych kamer i rekorderów umożliwia obserwację i archiwizowanie 
          materiału z długiego okresu czasu przy stosunkowo niewielkim wkładzie finansowym."
          row1="Podczas planowania systemów monitoringu, dokładamy wszelkich starań, aby rozmieszczenie kamer
          było optymalne. Dzięki uprzedniemu rozplanowaniu, zmniejsza się ostateczny koszt założenia oraz utrzymania
          instalacji."
          row2={
            <div>
              Montujemy oraz serwisujemy wszystkie typy instalacji:
              <ul>
                <li>Monitoring analogowy</li>
                <li>Monitoring hybrydowy</li>
                <li>Monitoring IP</li>
                <li>Monitoring HD-TVI</li>
              </ul>
            </div>
          }
        />
      );
    },
    () => {
      return (
        <OfferContent
          title="Instalacje domofonowe"
          intro="Domofonami i wideodomofonami zajmujemy się praktycznie od początku prowadzenia działalności.
        Mamy lata doświadczenia w montażu i serwisie tych urządzeń."
          row1="
        W naszej ofercie znajdują się wszystkie typy instalacji które nie tylko montujemy, ale także serwisujemy. 
        Do Waszej dyspozycji są systemy analogowe, cyfrowe podstawowe, a także zaawansowane systemy domofonowe IP oparte o infrastrukturę sieciową."
          row2="Dysponujemy rozbudowaną ofertą produktów, które nasi Klienci mają do wyboru. 
        Aby sprostać wymaganiom, sięgamy po nowoczesną technologię, zarówno polskich, jak i światowych producentów. Nasze profesjonalne podejście do klientów jest gwarancją doskonałej komunikacji w Waszym domu."
        />
      );
    },
    () => {
      return (
        <OfferContent
          title="Instalacje alarmowe"
          intro="System alarmowy to zbiór urządzeń, który zabezpiecza dany obszar przed włamaniem i odpowiednio doposażony przed pożarem.
         Sprawny system zapewnia wysoki poziom bezpieczeństwa i skutecznie odstrasza nieproszonych gości."
          row1="Oferujemy przeprowadzenie procesu instalacji systemu alarmowego, 
         począwszy od rozplanowania zgodnie z wymaganiami klienta i skończywszy na montażu. 
         Korzystamy z nowych i skutecznych urządzeń, które zapewniają niezawodną ochronę obszaru."
          row2={
            <div>
              Montujemy oraz serwisujemy wszystkie typy instalacji:
              <ul>
                <li>Monitoring analogowy</li>
                <li>Monitoring hybrydowy</li>
                <li>Monitoring IP</li>
                <li>Monitoring HD-TVI</li>
              </ul>
            </div>
          }
        />
      );
    },
  ];
  const [contentIndex, setContentIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  function closeModal(e) {
    e.preventDefault();
    setIsOpen(false);
  }

  function openModal(index) {
    setContentIndex(index);
    setIsOpen(true);
  }

  return (
    <>
      <StyledModal
        isOpen={isOpen}
        onBackgroundClick={closeModal}
        onEscapeKeydown={closeModal}
      >
        <Wrapper>
          <CloseButton onClick={closeModal}>
            <GiCancel />
          </CloseButton>
          {modalContent[contentIndex]()}
        </Wrapper>
      </StyledModal>
      <OfferSectionContainer>
        <OfferGridContainer>
          <CategoryButton
            isActive={props.isActive}
            delay={"0.1s"}
            onClick={() => openModal(0)}
            icon={<MdNote />}
            text="Świadectwa energetyczne"
          />
          <CategoryButton
            isActive={props.isActive}
            delay={"0.2s"}
            onClick={() => openModal(1)}
            icon={<GiToolbox />}
            text="Instalacje/pomiary elektryczne"
          />
          <CategoryButton
            isActive={props.isActive}
            delay={"0.3s"}
            onClick={() => openModal(2)}
            icon={<GiCctvCamera />}
            text="Systemy monitoringu"
          />
          <CategoryButton
            isActive={props.isActive}
            delay={"0.6s"}
            onClick={() => openModal(3)}
            icon={<MdDialpad />}
            text="Instalacje domofonowe"
          />
          <CategoryButton
            isActive={props.isActive}
            delay={"0.9s"}
            onClick={() => openModal(4)}
            icon={<FiAlertTriangle />}
            text="Instalacje alarmowe"
          ></CategoryButton>
        </OfferGridContainer>
      </OfferSectionContainer>
    </>
  );
};

export default OfferSection;
