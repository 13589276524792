import { useEffect } from "react";
import { useState } from "react";
import {
  AboutSectionContainer,
  AboutCardContainer,
  AboutNumbersContainer,
  AboutTextContainer,
  AboutTextFieldLeft,
  AboutTextFieldRight,
  ImageContainer,
  NumberContainer,
  CounterContainer,
  NumberInfoContainer,
} from "./about-section.styles";

const AboutSection = (props) => {

  const [shown, setShown] = useState(false);

  useEffect(()=>{
    if(props.isActive)
      setShown(true);
  },[props.isActive]);

  const numbers = () => {
    return(
      <AboutNumbersContainer>
          <CounterContainer>
            <NumberContainer
              start={0}
              end={24}
              duration={6}
              useEasing={true}
              useGrouping={true}
              prefix="+"
            >
              </NumberContainer>
            <NumberInfoContainer>LATA NA RYNKU</NumberInfoContainer>
          </CounterContainer>
          <CounterContainer>
            <NumberContainer
              start={0}
              end={500}
              duration={6}
              useEasing={true}
              useGrouping={true}
              prefix="+"
            />
            <NumberInfoContainer>WYKONANYCH ZLECEŃ</NumberInfoContainer>
          </CounterContainer>
          <CounterContainer>
            <NumberContainer
              start={0}
              end={1000}
              duration={6}
              useEasing={true}
              useGrouping={true}
              prefix="+"
            />
            <NumberInfoContainer>ZADOWOLONYCH KLIENTÓW</NumberInfoContainer>
          </CounterContainer>
        </AboutNumbersContainer>
    );
  }
  return (
    <AboutSectionContainer>
      <AboutCardContainer>
        <AboutTextContainer>
          <AboutTextFieldLeft isActive={props.isActive} delay={"0s"}>
            Działamy na rynku od 1997 roku. Nasza oferta jest skierowana m.in. do osób prywatnych,
            Spółdzielni Mieszkaniowych, Wspólnot Mieszkaniowych, deweloperów
            oraz architektów.
          </AboutTextFieldLeft>
          <ImageContainer src={"e-work.png"} />
        </AboutTextContainer>
        <AboutTextContainer>
          <ImageContainer src={"e-plan.png"} />
          <AboutTextFieldRight isActive={props.isActive} delay={"0.3s"}>
            Mamy wieloletnie doświadczenie w świadczeniu usług określonych w
            ofercie. Fachowość oraz solidność wykonywanej pracy sprawia, że jej
            efekt jest trwały i łatwy w konserwacji.
          </AboutTextFieldRight>
        </AboutTextContainer>
        {shown ? numbers() : null}
      </AboutCardContainer>
    </AboutSectionContainer>
  );
};

export default AboutSection;
