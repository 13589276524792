import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const TooltipWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  margin: 0.2rem;
`;

const TooltipTip = styled.div`
  position: absolute;
  border-radius: 0.25rem;
  left: 5%;
  padding: 0.475rem;
  color: white;
  background: black;
  font-size: 1.1rem;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
  animation: ${fadeIn} 0.3s ease-in-out 1;

  &::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 0.375rem;
    margin-left: -0.375rem;
  }

  &.top {
    top: -1.875rem;

    &::before {
      top: 100%;
      border-top-color: black;
    }
  }

  &.right {
    left: calc(100% + 1.875rem);
    top: 50%;
    transform: translateX(0) translateY(-50%);

    &::before {
      left: -0.375rem;
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-right-color: black;
    }
  }

  &.bottom {
    bottom: -1.875rem;

    &::before {
      bottom: 100%;
      border-bottom-color: black;
    }
  }

  &.left {
    left: auto;
    right: calc(100% + 1.875rem);
    top: 50%;
    transform: translateX(0) translateY(-50%);

    &::before {
      left: auto;
      right: -0.75rem;
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-left-color: black;
    }
  }
`;

export { TooltipWrapper, TooltipTip };
