import { useRef } from "react";
import emailjs from "emailjs-com";

import {
  FormCard,
  ContentsInput,
  FormPlaceholder,
  NameInput,
  PhoneMailInput,
  SubmitInput,
  StyledTitle,
} from "./contact-form.styles";

import Alert, {
  AlertTypes,
  useAlert,
} from "../../shared/alert/alert.component";


const ContactForm = () => {
  const form = useRef();
  const name = useRef("");
  const phone = useRef("");
  const mail = useRef("");
  const message = useRef("");

  const { isOpen, contents, type, delay, fireAlert } = useAlert(3000);

  const formSubmit = (e) => {
    function formValidation() {
      let phoneReg = /^[\+]?[0-9]{9,12}$/im;
      let mailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      phone.current.value = phone.current.value.replace(/\s/g, "");

      if (name.current.value.length < 7) {
        throw Error("Niepoprawne imię i nazwisko.");
      }

      if (!phoneReg.test(phone.current.value)) {
        throw Error("Niepoprawny numer telefonu.");
      }

      if (!mailReg.test(mail.current.value)) {
        throw Error("Niepoprawny adres email.");
      }

      if (message.current.value.length < 20) {
        throw Error("Za krótka treść wiadomości.");
      }
    }

    e.preventDefault();

    try {
      formValidation();

      emailjs
        .sendForm(
          "service_hi13wbb",
          "template_7sdrpte",
          form.current,
          "acwFS8fHf3I8wGBZt"
        )
        .then(
          (result) => {
            fireAlert("Zapytanie wysłano pomyślnie!", AlertTypes.Success);
            form.current.reset();
          },
          (error) => {
            fireAlert("Wysłanie zapytania nie powiodło się.", AlertTypes.Error);
          }
        );

    } catch (e) {
      fireAlert(e.message, AlertTypes.Error);
    }
  };
  return (
    <FormCard>
      <StyledTitle>Formularz Kontaktowy</StyledTitle>
      <Alert active={isOpen} contents={contents} type={type} delay={delay} />
      <FormPlaceholder ref={form} onSubmit={formSubmit}>
        <NameInput
          placeholder="Imię i nazwisko"
          ref={name}
          name="from_name"
          required
        />
        <PhoneMailInput
          type="tel"
          placeholder="Numer telefonu"
          ref={phone}
          name="from_phone"
          required
        />
        <PhoneMailInput
          type="email"
          placeholder="Adres e-mail"
          ref={mail}
          name="from_mail"
          required
        />
        <ContentsInput
          placeholder="Treść wiadomości"
          ref={message}
          name="from_contents"
          required
        />
        <SubmitInput type="submit" />
      </FormPlaceholder>
    </FormCard>
  );
};

export default ContactForm;
