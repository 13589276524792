import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFlip } from "swiper";
import { useRef, useState } from "react";
import emailjs from "emailjs-com";
import "./evidenceswiper.css";
import EvidenceFormFirst from "../evidence-form/evidence-form-first/evidence-form-first.component";
import EvidenceFormSecond from "../evidence-form/evidence-form-second/evidence-form-second.component";
import EvidenceFormThird from "../evidence-form/evidence-form-third/evidence-form-third.component";
import {
  EvidenceSectionContainer,
  StyledTitle,
  FormCard,
  FormPlaceholder,
  StyledButton,
  ButtonsContainer,
} from "./evidence-section.styles";
import Alert, {
  AlertTypes,
  useAlert,
} from "../../shared/alert/alert.component";

const EvidenceSection = (props) => {
  const form = useRef();

  const name = useRef("");
  const phone = useRef("");
  const mail = useRef("");
  const address = useRef("");
  const [evidenceType, setEvidenceType] = useState("Mieszkanie");
  const evidenceAddress = useRef("");
  const areaSize = useRef("");
  const height = useRef("");
  const usageYear = useRef("");

  const buildingMaterial = useRef("");
  const wallInsulation = useRef("");
  const roofInsulation = useRef("");
  const floor = useRef("");
  const isOnLastFloor = useRef("");
  const isMiddle = useRef("");
  const windowLayout = useRef("");

  const windowsMeasurement = useRef("");
  const heatingType = useRef("");
  const builtInLighting = useRef("");
  const ac = useRef("");
  const ventilationType = useRef("");
  const usageDaysPerWeek = useRef("");
  const usageHoursPerDay = useRef("");
  const heatPump = useRef("");
  const photovoltaics = useRef("");

  function validateFirstPart() {
    let phoneReg = /^[\+]?[0-9]{9,12}$/im;
    let mailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (name.current.value.length <= 0) {
      throw Error("Niepoprawne imię.");
    }
    if (!phoneReg.test(phone.current.value)) {
      throw Error("Niepoprawny numer telefonu.");
    }
    if (!mailReg.test(mail.current.value)) {
      throw Error("Niepoprawny adres email.");
    }
    if (address.current.value.length <= 0) {
      throw Error("Adres nie może być pusty.");
    }
    if (evidenceType === "") {
      throw Error("Wybierz typ nieruchomości.");
    }
    if (evidenceAddress.current.value.length <= 0) {
      throw Error("Adres nieruchomości nie może być pusty.");
    }
    if (areaSize.current.value.length <= 0) {
      throw Error("Powierzchnia nie może być pusta.");
    }
    if (height.current.value.length <= 0) {
      throw Error("Wysokość nie może być pusta.");
    }
    if (usageYear.current.value.length <= 0) {
      throw Error("Rok użytkowania budynku nie może być pusty.");
    }
    return true;
  }

  function validateSecondPart() {
    if (buildingMaterial.current.value.length <= 0) {
      throw Error("Materiał budynku nie może być pusty.");
    }
    if (floor.current.value.length <= 0) {
      throw Error("Numer piętra nie może być pusty.");
    }
    if (
      isOnLastFloor.current.value !== "true" &&
      isOnLastFloor.current.value !== "false"
    ) {
      throw Error(
        "Opcja 'Czy na ostatnim piętrze?' może być tylko prawda lub fałsz."
      );
    }
    if (
      isMiddle.current.value !== "true" &&
      isMiddle.current.value !== "false"
    ) {
      throw Error("Opcja 'Czy w środku?' może być tylko prawda lub fałsz.");
    }
    if (windowLayout.current.value.length <= 0) {
      throw Error("Rozkład nie może być pusty.");
    }
    return true;
  }

  function validateThirdPart() {
    const isHouse = evidenceType === "Dom";
    const isPublic = evidenceType === "Lokal użytkowy";

    if (windowsMeasurement.current.value.length <= 0) {
      throw Error("Wymiary okien nie mogą być puste.");
    }
    if (heatingType.current.value.length <= 0) {
      throw Error("Typ ogrzewania nie może być pusty.");
    }
    if (isPublic && builtInLighting.current.value.length <= 0) {
      throw Error("Opis oświetlenia nie może być pusty.");
    }
    if (isPublic || isHouse) {
      if (ac.current.value.length <= 0) {
        throw Error("Opis klimatyzacji nie może być pusty.");
      }
      if (ventilationType.current.value.length <= 0) {
        throw Error("Typ wentylacji nie może być pusty.");
      }
      if (isNaN(usageDaysPerWeek.current.value)) {
        throw Error("Liczba dni użytkowania w tygodniu musi być liczbą.");
      }
      if (
        usageDaysPerWeek.current.value < 0 ||
        usageDaysPerWeek.current.value > 7
      ) {
        throw Error("Liczba dni użytkowania w tygodniu musi być między 0 a 7.");
      }
      if (isNaN(usageHoursPerDay.current.value)) {
        throw Error("Liczba godzin użytkowania na dzień musi być liczbą.");
      }
      if (
        usageHoursPerDay.current.value < 0 ||
        usageHoursPerDay.current.value > 24
      ) {
        throw Error(
          "Liczba godzin użytkowania na dzień musi być między 0 a 24."
        );
      }
    }
    if (isHouse) {
      if (
        heatPump.current.value !== "true" &&
        heatPump.current.value !== "false"
      ) {
        throw Error(
          "Opcja 'Czy z pompą ciepła?' może być tylko prawda lub fałsz."
        );
      }
      if (
        photovoltaics.current.value !== "true" &&
        photovoltaics.current.value !== "false"
      ) {
        throw Error(
          "Opcja 'Czy z fotowoltaiką?' może być tylko prawda lub fałsz."
        );
      }
    }
    return true;
  }

  const [swiper, setSwiper] = useState(null);
  const [slideIndex, setSlideIndex] = useState(0);

  const { isOpen, contents, type, delay, fireAlert } = useAlert(3000);

  function nextFirst(ev) {
    ev.preventDefault();
    try {
      validateFirstPart();
      swiper.slideTo(1);
    } catch (e) {
      fireAlert(e.message, AlertTypes.Error);
    }
  }

  function nextSecond(ev) {
    ev.preventDefault();
    try {
      validateFirstPart();
      validateSecondPart();
      swiper.slideTo(2);
    } catch (e) {
      fireAlert(e.message, AlertTypes.Error);
    }
  }

  function back(ev) {
    ev.preventDefault();
    swiper.slideTo(slideIndex - 1);
  }

  function submit(ev) {
    ev.preventDefault();
    try {
      validateFirstPart();
      validateSecondPart();
      validateThirdPart();

      emailjs
        .sendForm(
          "service_hi13wbb",
          "template_5zxwxw9",
          form.current,
          "acwFS8fHf3I8wGBZt"
        )
        .then(
          (result) => {
            fireAlert("Zapytanie wysłano pomyślnie!", AlertTypes.Success);
            
            form.current.reset();
            setEvidenceType("Mieszkanie");
            swiper.slideTo(0);
          },
          (error) => {
            fireAlert("Wysłanie zapytania nie powiodło się.", AlertTypes.Error);
          }
        );
    } catch (e) {
      fireAlert(e.message, AlertTypes.Error);
    }
  }

  function buttonRender() {
    switch (slideIndex) {
      case 0:
        return (
          <ButtonsContainer>
            <StyledButton onClick={nextFirst}>Dalej</StyledButton>
          </ButtonsContainer>
        );
      case 1:
        return (
          <ButtonsContainer>
            <StyledButton onClick={back}>Wróć</StyledButton>
            <StyledButton onClick={nextSecond}>Dalej</StyledButton>
          </ButtonsContainer>
        );
      case 2:
        return (
          <ButtonsContainer>
            <StyledButton onClick={back}>Wróć</StyledButton>
            <StyledButton onClick={submit}>Wyślij</StyledButton>
          </ButtonsContainer>
        );
      default:
        return null;
    }
  }

  return (
    <EvidenceSectionContainer>
      <StyledTitle>Formularz zlecenia świadectwa</StyledTitle>
      <Alert active={isOpen} contents={contents} type={type} delay={delay} />
      <FormCard>
        <FormPlaceholder ref={form} onSubmit={null}>
          <Swiper
            speed={750}
            slidesPerView={1}
            allowTouchMove={false}
            focusableElements={false}
            effect={"flip"}
            loop={false}
            className="evidenceSwiper evidenceswiper"
            modules={[EffectFlip]}
            onSlideChange={(s) => {
              setSlideIndex(s.realIndex);
            }}
            onSwiper={(s) => {
              setSwiper(s);
            }}
          >
            <SwiperSlide className="evidenceslide">
              <EvidenceFormFirst
                name={name}
                phone={phone}
                mail={mail}
                address={address}
                evidenceType={evidenceType}
                setEvidenceType={setEvidenceType}
                evidenceAddress={evidenceAddress}
                areaSize={areaSize}
                height={height}
                usageYear={usageYear}
              />
            </SwiperSlide>
            <SwiperSlide className="evidenceslide">
              <EvidenceFormSecond
                buildingMaterial={buildingMaterial}
                wallInsulation={wallInsulation}
                roofInsulation={roofInsulation}
                floor={floor}
                isOnLastFloor={isOnLastFloor}
                isMiddle={isMiddle}
                windowLayout={windowLayout}
              />
            </SwiperSlide>
            <SwiperSlide className="evicdenceslide">
              <EvidenceFormThird
                windowsMeasurement={windowsMeasurement}
                heatingType={heatingType}
                builtInLighting={builtInLighting}
                ac={ac}
                ventilationType={ventilationType}
                usageDaysPerWeek={usageDaysPerWeek}
                usageHoursPerDay={usageHoursPerDay}
                heatPump={heatPump}
                photovoltaics={photovoltaics}
                evidenceType={evidenceType}
              />
            </SwiperSlide>
          </Swiper>
          {buttonRender()}
        </FormPlaceholder>
      </FormCard>
    </EvidenceSectionContainer>
  );
};

export default EvidenceSection;
