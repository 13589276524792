import {
    ImageContainer,
    StyledImage,
} from './home-image.styles';

const HomeImage = (props) =>{
    return(
        <ImageContainer>
            <StyledImage src={props.src}/>
        </ImageContainer>
    )
}

export default HomeImage;