import styled from "styled-components";
import { device } from "../../../constants/device-sizes";

export const ContentContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: "Monda";
  @media ${device.laptop} {
    font-size: 20px;
  }
`;

export const TitleContainer = styled.h2`
  width: 100%;
  height:auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid darkgray;
  padding-bottom:10px;
  margin-bottom:0;
`;

export const IntroContainer = styled.h3`
  width: 90%;
  display:flex;
  flex-direction:column;
  justify-content:start;
  align-items:start;
`;

export const RowContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 2px solid darkgray;
  @media ${device.laptop} {
    align-items: start;
    flex-direction: row;
    justify-content:space-around;
  }
`;

export const RowTextContainer = styled.h4`
  width: 100%;
  display: flex;
  @media ${device.laptop} {
    width: 48%;
  }
`;
