import styled from 'styled-components';
import { device } from '../../constants/device-sizes';

export const NavbarContainer = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    width:100%;
    height:8%;
    background-color:rgba(0,0,0,0.3);
    color:white;
    z-index:100;
    pointer-events:none;
`;

export const ButtonsContainer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  height:100%;
  width:100%;
`;

export const StyledButton = styled.button`
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:rgba(0,0,0,0);
  padding-left:1rem;
  padding-right:1rem;
  border:0px;
  font-size:${props => props.chosen ? "1.25rem" : "1rem"};
  color:${props => props.chosen ? "yellow" : "white"};
  height:100%;
  width:auto;
  transition-duration:350ms;
  pointer-events:all;
  font-family:"Big Shoulders Text";
  :hover{
    background-color:${props => props.chosen ? "" : "rgba(255,255,255,0.1)"};
  }

  @media ${device.laptop} { 
    font-size:${props => props.chosen ? "2rem" : "1.5rem"};
  }
`;

