import styled from "styled-components";
import { device } from '../../../constants/device-sizes';

export const LogoPlacer = styled.div`
  width:100%;
  height:100%;
  position:absolute;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;  
  z-index:2;
`;

export const LogoContainer = styled.img`
  min-width: 50%;
  max-width: 100%;
  height:auto;
  object-fit:contain;
`;

export const StyledSpan = styled.span`

@keyframes fadein {
    from {color:rgba(255,255,255,0);}
    to {color:rgba(255,255,255,1);}
}
margin-top:10px;
  min-width:50%;
  max-width:80%;
  color:white;
  font-size:26px;
  display:flex;
  flex-direction:column;
  align-items:center;
  animation-name:fadein;
  animation-duration:2s;
  @media ${device.laptop} { 
        font-size:30px;
    }
`;