import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
import HomeImage from "../home-image/home-image.component";

import "./homeswiper.css";
import { LogoPlacer, LogoContainer, StyledSpan } from "./home-section.styles";
import { useState } from "react";
const HomeSection = (props) => {
  const slideText = [
    "ŚWIADECTWA ENERGETYCZNE",
    "INSTALACJE ELEKTRYCZNE",
    "SYSTEMY MONITORINGU",
    "INSTALACJE DOMOFONOWE",
    "INSTALACJE ALARMOWE",
  ];
  const [currText, setCurrText] = useState(slideText[0]);
  return (
    <>
      <LogoPlacer>
        <LogoContainer src="abd-white.png" />
        <StyledSpan key={currText}>{currText}</StyledSpan>
      </LogoPlacer>

      <Swiper
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        speed={3000}
        slidesPerView={1}
        effect={"fade"}
        loop={true}
        modules={[Autoplay, EffectFade]}
        className="homeSwiper homeswiper"
        onSlideChange={(s) => {
          setCurrText(slideText[s.realIndex]);
        }}
      >
        <SwiperSlide className="homeslide">
          <HomeImage src="s-scheme.png" />
        </SwiperSlide>
        <SwiperSlide className="homeslide">
          <HomeImage src="s-electrician.png" />
        </SwiperSlide>
        <SwiperSlide className="homeslide">
          <HomeImage src="s-camera.png" />
        </SwiperSlide>
        <SwiperSlide className="homeslide">
          <HomeImage src="s-phone.png" />
        </SwiperSlide>
        <SwiperSlide className="homeslide">
          <HomeImage src="s-alarm.png" />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default HomeSection;
