import { useState } from "react";
import { AlertWrapper } from "./alert.styles.jsx";
import {
  AiOutlineAlert,
  AiOutlineCheck,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { useEffect } from "react";

export const AlertTypes = {
  Warning: "warning",
  Error: "error",
  Success: "success",
};

const BgColors = {
  warning: "rgba(155,155,0,0.85)",
  error: "rgba(155,0,0,0.85)",
  success: "rgba(0,155,0,0.85)",
};

const AlertIcons = {
  warning: () => <AiOutlineAlert />,
  error: () => <AiOutlineCloseCircle />,
  success: () => <AiOutlineCheck />,
};

export function useAlert(delay) {
  const [isOpen, setIsOpen] = useState(false);
  const [contents, setContents] = useState("");
  const [type, setType] = useState("success");

  useEffect(() => {
    if (isOpen === true) {
      setTimeout(() => {
        setIsOpen(false);
      }, delay);
    }
  }, [isOpen]);

  const fireAlert = (contents, type) => {
    setType(type);
    setContents(contents);
    setIsOpen(true);
  };

  return {isOpen, contents, type, delay, fireAlert}
}

const Alert = (props) => {
  const { active, contents, type } = props;
  return (
    <>
      {active && (
        <AlertWrapper
          $isActive={active}
          $bgColor={BgColors[type]}
          $animTime={props.delay - 25 + "ms"}
        >
          <span>{AlertIcons[type]()}</span>
          {contents}
        </AlertWrapper>
      )}
    </>
  );
};

export default Alert;
