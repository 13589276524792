import './App.css';
import RouterWrapper from './pages/home/home-page.component';
import {ModalProvider} from 'styled-react-modal';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCKwUlI12xs5knbTtQtsK-syiC2iNPyZGk",
  authDomain: "abd-page.firebaseapp.com",
  projectId: "abd-page",
  storageBucket: "abd-page.appspot.com",
  messagingSenderId: "511208778999",
  appId: "1:511208778999:web:b4c3225ae594a3294377f2",
  measurementId: "G-ZKQX7156V9"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {  
  document.addEventListener('keydown', function(e){ if (e.keyCode == 9)  e.preventDefault() });
  return (
    <ModalProvider>
      <RouterWrapper/>
    </ModalProvider>
  );
}

export default App;
