import styled, { keyframes } from "styled-components";

const rollIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  25% {
    opacity: 1;
    transform: translateX(0);
  }

  75% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
`;

export const AlertWrapper = styled.div`
  position: fixed;
  display: inline-block;
  margin: 0.2rem;
  transition: 300ms;
  background-color: ${(props)=>props.$bgColor};
  border-radius: 10px;
  padding: 1.5rem;
  font-size: 2rem;
  color: white;
  z-index: 1000;
  top: 66%;
  pointer-events: none;

  animation: ${rollIn} ${(props) => (props.$animTime)} ease-in-out forwards;
`;
