import {
  NavbarContainer,
  ButtonsContainer,
  StyledButton,
} from "./navbar.styles";

const Navbar = (props) => {
  const chosen = props.currentSlide;
  return (
    <NavbarContainer>
      <ButtonsContainer>
        <StyledButton chosen={chosen === 1} onClick={()=>props.slideTo(2)}>
          O NAS
        </StyledButton>
        <StyledButton  chosen={chosen === 2} onClick={()=>props.slideTo(3)}>
          OFERTA
        </StyledButton>
        <StyledButton  chosen={chosen === 3} onClick={()=>props.slideTo(4)}>
          ŚWIADECTWA
        </StyledButton>
        <StyledButton chosen={chosen === 4} onClick={()=>props.slideTo(5)}>
          KONTAKT
        </StyledButton>
      </ButtonsContainer>
    </NavbarContainer>
  );
};

export default Navbar;
