import styled from "styled-components";
import { device } from '../../../constants/device-sizes';

export const PersonalsCard = styled.div`
  display: flex;
  margin-top:1rem;
  margin-bottom:1rem;
  width: 80%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.7);

  @media ${device.laptop} { 
    font-size:40px;
  }
`;

export const PersonalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: space-around;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
`;

export const ShowButton = styled.button`
  width:50%;
  height:70%;
  margin-left:20px;
  border: 0px;
  background-color:rgba(100,100,100,0.1);
  color:white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  transition-duration:350ms;
  pointer-events:all;
  font-family:"Big Shoulders Text";
  :hover{
    background-color:${props => props.chosen ? "" : "rgba(255,255,255,0.1)"};
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px 20px 5px 20px;
  align-items: center;
`;

export const IconContainer = styled.div`
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
`;

export const InfoTextContainer = styled.a`
  width: 90%;
  transition: 500ms;
  display: flex;
  justify-content: left;
  align-items: center;
  text-decoration: none;
  color: white;
  :hover {
    color: yellow;
  }
`;
