import { useRef } from "react";
import emailjs from "emailjs-com";

import {
  NameInput,
  PhoneMailInput,
  SelectInput,
  SelectOption,
  InputGrid,
  FormContainer,
  FormSection,
  FormRow,
  StyledTitle,
  StyledSubtitle,
  ContentsInput,
} from "../evidence-form.styles";
import Tooltip from "../../../shared/tooltip/tooltip.component";

const EvidenceFormThird = (props) => {
  const {
    windowsMeasurement,
    heatingType,
    builtInLighting,
    ac,
    ventilationType,
    usageDaysPerWeek,
    usageHoursPerDay,
    heatPump,
    photovoltaics,
    evidenceType,
  } = props;

  const isPublic = evidenceType === "Lokal użytkowy";
  const isHouse = evidenceType === "Dom";

  return (
    <FormContainer>
      <FormSection>
        <StyledTitle>Dane nieruchomości cz.3</StyledTitle>
        <Tooltip content="Wymiary okien (szerokość x wysokość w cm)">
          <NameInput
            type="text"
            placeholder="Wymiary okien (szerokość x wysokość w cm, np. 180cm x 90cm)"
            ref={windowsMeasurement}
            name="from_windowsMeasurement"
          />
        </Tooltip>
        <Tooltip content="Rodzaj ogrzewania i dostarczania ciepłej wody">
          <NameInput
            type="text"
            placeholder="Rodzaj ogrzewania i dostarczania ciepłej wody (np. sieć miejska, kotłownia gazowa/węglowa itp)"
            ref={heatingType}
            name="from_heatingType"
          />
        </Tooltip>
        {isPublic && (
          <Tooltip content="Rodzaj i moc oświetlenia wbudowanego">
            <NameInput
              type="text"
              placeholder="Rodzaj i moc oświetlenia wbudowanego (np. lampy LED, świetlówki, oświetlenie żarowe.)"
              ref={builtInLighting}
              name="from_builtInLighting"
            />
          </Tooltip>
        )}
        {(isPublic || isHouse) && (
          <>
            <Tooltip content="Product i moc klimatyzacji">
              <NameInput
                type="number"
                placeholder="Product i moc klimatyzacji np. (Bosch 200W)"
                ref={ac}
                name="from_ac"
              />
            </Tooltip>
            <FormRow>
              <Tooltip content="Rodzaj wentylacji">
                <SelectInput ref={ventilationType}>
                  <SelectOption value={"Wentylacja grawitacyjna"}>
                    Wentylacja grawitacyjna
                  </SelectOption>
                  <SelectOption value={"Wentylacja mechaniczna"}>
                    Wentylacja mechaniczna
                  </SelectOption>
                  <SelectOption value={"Wentylacja rekuperacyjna"}>
                    Wentylacja rekuperacyjna
                  </SelectOption>
                </SelectInput>
              </Tooltip>
              <Tooltip content="Ilość dni użytkowania w tygodniu">
                <NameInput
                  type="number"
                  placeholder="Ilość dni użytkowania wentylacji w tygodniu"
                  ref={usageDaysPerWeek}
                  name="from_usageDaysPerWeek"
                />
              </Tooltip>
              <Tooltip content="Ilość godzin użytkowania dziennie">
                <NameInput
                  type="number"
                  placeholder="Ilość godzin użytkowania wentylacji dziennie"
                  ref={usageHoursPerDay}
                  name="from_usageHoursPerDay"
                />
              </Tooltip>
            </FormRow>
          </>
        )}
        {isHouse && (
          <>
            <FormRow>
              <StyledSubtitle>Pompa ciepła</StyledSubtitle>
              <Tooltip content="Czy posiadasz pompę ciepła?">
                <SelectInput ref={heatPump}>
                  <SelectOption value={false}>Nie</SelectOption>
                  <SelectOption value={true}>Tak</SelectOption>
                </SelectInput>
              </Tooltip>
            </FormRow>
            <FormRow>
              <StyledSubtitle>Fotowoltaika</StyledSubtitle>
              <Tooltip content="Czy posiadasz fotowoltaikę?">
                <SelectInput ref={photovoltaics}>
                  <SelectOption value={false}>Nie</SelectOption>
                  <SelectOption value={true}>Tak</SelectOption>
                </SelectInput>
              </Tooltip>
            </FormRow>
          </>
        )}
      </FormSection>
    </FormContainer>
  );
};

export default EvidenceFormThird;
