import styled from "styled-components";
import { device } from "../../../constants/device-sizes";

export const FormCard = styled.div``;

export const FormPlaceholder = styled.form`
  height: 80%;
  width: 100%;
`;

export const FormContainer = styled.div`
margin-top:0.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledTitle = styled.div`
  font-size: 1em;
  color: white;
`;

export const StyledSubtitle = styled.div`
  min-width: 25%;
  margin: 0.5rem;
  font-size: 0.7em;
  color: white;
`;

export const FormSection = styled.div`
  width:90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

export const FormRow = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const InputGrid = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 15% 15% 15% 15% 15% 15%;
  gap: 3%;
  padding: 5%;
`;

const BaseInput = styled.input`
  width: 100%;
  padding: 0.4rem;
  margin: 0.3rem;
  box-sizing: border-box;
  color: white;
  background-color: #07070c;
  border: 0px;
  transition: 300ms;
  font-family: "Monda";
  :focus {
    background-color: #1f2237;
  }
  font-size: 0.75rem;
  @media ${device.laptop} {
    font-size: 1.3rem;
  }
`;

export const AddressNumbersContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const NameInput = styled(BaseInput)`
  width: 100%;
`;

export const PhoneMailInput = styled(BaseInput)``;

export const SelectInput = styled.select`
  width: 100%;
  padding: 0.4rem;
  margin: 0.3rem;
  box-sizing: border-box;
  color: white;
  background-color: #07070c;
  border: 0px;
  transition: 300ms;
  font-family: "Monda";
  :focus {
    background-color: #1f2237;
  }
  font-size: 0.75rem;
  @media ${device.laptop} {
    font-size: 1.3rem;
  }
`;

export const SelectOption = styled.option`
  color: white;
  @media ${device.laptop} {
    font-size: 1.3rem;
  }
  transition: 300ms;
  padding: 2%;
  font-family: "Monda";
  :hover {
    color: yellow;
  }
`;

export const ContentsInput = styled.textarea`
  width: 100%;
  min-height:20%;
  padding: 0.6rem;
  margin: 0.5rem;
  box-sizing: border-box;
  color: white;
  background-color: #07070c;
  border: 0px;
  transition: 300ms;
  font-family: "Monda";
  :focus {
    background-color: #1f2237;
  }
  font-size: 0.7rem;
  @media ${device.laptop} {
    font-size: 1.3rem;
  }
`;

export const SubmitInput = styled(BaseInput)`
  grid-column-start: 1;
  grid-column-end: 3;
  background-color: #07070c;
  :hover {
    background-color: #1f2237;
  }
`;
