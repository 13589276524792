import{
    CategoryPlaceholder,
    StyledButton,
    IconPlaceholder,
    TextPlaceholder,
} from './category-button.styles';

const CategoryButton = (props) => {
  return (
    <CategoryPlaceholder>
        <StyledButton onClick={props.onClick} isActive={props.isActive} delay={props.delay}>
        <IconPlaceholder>
            {props.icon}
        </IconPlaceholder>
        <TextPlaceholder>
            {props.text}
        </TextPlaceholder>
        </StyledButton>
    </CategoryPlaceholder>
  );
};
export default CategoryButton;
