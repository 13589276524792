import styled from "styled-components";
import { device } from "../../../constants/device-sizes";

export const FormCard = styled.div`
  min-width: 60%;
  max-width: 80%;
  min-height: 65%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTitle = styled.div`
  font-size: 1.3em;
  color: white;
`;

export const FormPlaceholder = styled.form`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 20% 20% 20% 20% 20%;
  gap: 1%;
  padding: 5%;
`;

const BaseInput = styled.input`
  color: white;
  background-color: #07070c;
  border: 0px;
  font-size: 0.9rem;
  transition: 300ms;
  padding: 2%;
  font-family: "Monda";
  :focus {
    background-color: #1f2237;
  }
  @media ${device.laptop} {
    font-size: 1.3rem;
  }
`;

export const NameInput = styled(BaseInput)`
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const PhoneMailInput = styled(BaseInput)``;

export const ContentsInput = styled.textarea`
  color: white;
  background-color: #07070c;
  border: 0px;
  transition: 300ms;
  padding: 2%;
  resize: none;
  font-size: 16px;
  font-family: "Monda";
  :focus {
    background-color: #1f2237;
  }
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 5;
`;

export const SubmitInput = styled(BaseInput)`
  grid-column-start: 1;
  grid-column-end: 3;
  background-color: #07070c;
  :hover {
    background-color: #1f2237;
  }
`;
