import { useState } from "react";

import {
  PersonalsCard,
  IconContainer,
  InfoContainer,
  InfoTextContainer,
  TextContainer,
  PersonalsContainer,
  ShowButton,
} from "./contact-personals.style";

import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";

const ContactPersonals = (props) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <PersonalsCard>
      <TextContainer>{props.name}</TextContainer>

      {isShown ? (
        <PersonalsContainer>
          <InfoContainer>
            <IconContainer>
              <AiOutlinePhone />
            </IconContainer>
            <InfoTextContainer href={"tel: " + props.phone}>
              {props.phone}
            </InfoTextContainer>
          </InfoContainer>
          <InfoContainer>
            <IconContainer>
              <AiOutlineMail />
            </IconContainer>
            <InfoTextContainer href={"mailto: " + props.email}>
              {props.email}
            </InfoTextContainer>
          </InfoContainer>
        </PersonalsContainer>
      ) : (
        <ShowButton onClick={() => setIsShown(true)}>Pokaż dane</ShowButton>
      )}
    </PersonalsCard>
  );
};

export default ContactPersonals;
