import { useState } from "react";
import emailjs from "emailjs-com";

import {
  NameInput,
  PhoneMailInput,
  SelectInput,
  SelectOption,
  ContentsInput,
  InputGrid,
  FormContainer,
  FormSection,
  FormRow,
  StyledTitle,
  StyledSubtitle,
} from "../evidence-form.styles";
import Tooltip from "../../../shared/tooltip/tooltip.component";

const EvidenceFormSecond = (props) => {
  const {
    buildingMaterial,
    wallInsulation,
    roofInsulation,
    floor,
    isOnLastFloor,
    isMiddle,
    windowLayout,
  } = props;

  const [showWallInsulation, setShowWallInsulation] = useState(false);
  const [showRoofInsulation, setShowRoofInsulation] = useState(false);

  const handleBoolSelect = (e, setFn) => {
    setFn(e.target.value === "true");
  };

  return (
    <FormContainer>
      <FormSection>
        <StyledTitle>Dane nieruchomości cz.2</StyledTitle>
        <Tooltip content="Sposób wykonania budynku (np. cegła, wielka płyta)">
          <NameInput
            placeholder="Sposób wykonania budynku (np. cegła, wielka płyta)"
            ref={buildingMaterial}
            name="from_buildingMaterial"
          />
        </Tooltip>
        <FormRow>
          <StyledSubtitle>Ocieplenie ścian zew.</StyledSubtitle>
          <Tooltip content="Czy występuje ocieplenie ścian zewnętrznych?">
            <SelectInput
              onChange={(e) => handleBoolSelect(e, setShowWallInsulation)}
            >
              <SelectOption value={false}>Nie</SelectOption>
              <SelectOption value={true}>Tak</SelectOption>
            </SelectInput>
          </Tooltip>
        </FormRow>
        {showWallInsulation === true ? (
          <Tooltip content="Materiał, grubość w cm np. styropian 10cm">
            <NameInput
              placeholder="Materiał, grubość w cm np. styropian 10cm"
              name="from_wallInsulation"
              ref={wallInsulation}
            />
          </Tooltip>
        ) : null}
        <FormRow>
          <StyledSubtitle>Ocieplenie stropodachu</StyledSubtitle>
          <Tooltip content="Czy występuje ocieplenie stropodachu?">
            <SelectInput
              onChange={(e) => handleBoolSelect(e, setShowRoofInsulation)}
            >
              <SelectOption value={false}>Nie</SelectOption>
              <SelectOption value={true}>Tak</SelectOption>
            </SelectInput>
          </Tooltip>
        </FormRow>
        {showRoofInsulation === true ? (
          <Tooltip content="Materiał np. granulat wełny mineralnej, styropian">
            <NameInput
              placeholder="Materiał np. granulat wełny mineralnej, styropian"
              name="from_roofInsulation"
              ref={roofInsulation}
            />
          </Tooltip>
        ) : null}
        <FormRow>
          <Tooltip content="Nr piętra">
            <NameInput
              type="number"
              placeholder="Nr piętra"
              ref={floor}
              name="from_floor"
            />
          </Tooltip>
          <Tooltip content="Piętro nieostatnie lub ostatnie">
            <SelectInput ref={isOnLastFloor}>
              <SelectOption value={false}>Piętro nieostatnie</SelectOption>
              <SelectOption value={true}>Piętro ostatnie</SelectOption>
            </SelectInput>
          </Tooltip>
          <Tooltip content="Lokal środkowy lub szczytowy">
            <SelectInput ref={isMiddle}>
              <SelectOption value={true}>Lokal środkowy</SelectOption>
              <SelectOption value={true}>Lokal szczytowy</SelectOption>
            </SelectInput>
          </Tooltip>
        </FormRow>
        <Tooltip content="Rozkład pomieszczeń z oknami">
          <ContentsInput
            placeholder="Rozkład pomieszczeń z oknami np.(okno balkonowe,drzwi balkonowe,okno sypialni na stronę zachodnią,okno kuchni na stronę wschodnią.)"
            ref={windowLayout}
            name="from_windowLayout"
          />
        </Tooltip>
      </FormSection>
    </FormContainer>
  );
};

export default EvidenceFormSecond;
