import styled from "styled-components";
import Modal from "styled-react-modal";

export const StyledModal = Modal.styled`
  @keyframes open {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  width: 80vw;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  background-color:#ccc ; 
  color:black;
  border: 1px solid #111111;
  border-radius: 50px;
  animation-name:open;
  animation-duration:0.5s;
  padding: 0.4rem;
  animation-timing-function:ease-in;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseButton = styled.span`
  width: 4.5vh;
  height: 4.5vh;
  position: absolute;
  top: 1vh;
  right: 1vw;
  transition: 300ms;
  font-size: 4.5vh;
  :hover {
    color: yellow;
  }
`;

export const OfferSectionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OfferGridContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto auto auto;
  width: 100%;
  height: 100%;
`;
